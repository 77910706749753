.screen {
	color: rgb(88, 218, 28);
	text-align: center;
	opacity: 0.8;
	text-shadow: 0em 0em 0.7em rgba(90, 194, 42, 0.692);
}

.button {
	color: inherit;
	border-style: none;
	border-bottom: 3px solid rgb(88, 218, 28);
	background-color: rgba(246, 13, 13, 0);
	font-family: inherit;
	font-weight: 600;
	font-size: 4vw;
	text-shadow: inherit;
	cursor:pointer
	
}

.pointer {
	cursor:pointer
}

.arrow {
	filter: invert(48%) sepia(79%) saturate(400%) hue-rotate(60deg)
		brightness(118%) contrast(119%) drop-shadow(0px 0px 0.7em rgba(90, 194, 42, 0.692));
	width: 6vw;
	padding: 0 1vw;
	margin-bottom: 2vh;
}
.screen h1 {
	margin-bottom: 5%;
	font-weight: 400;
	font-size: 4vw;
}
.screen p {
	font-weight: 400;
	font-size: 4vw;
}
.ok {
	display: block;
	margin: auto;
}

