body {
	background: rgb(215, 187, 138);
	background: linear-gradient(
		0deg,
		rgba(215, 187, 138, 1) 0%,
		rgba(179, 141, 94, 1) 100%
	);
}

* {
	overflow: hidden;
}
.app {
	/* position: fixed;     */
	height: 100vh;
}

.screen {
	/* height: 100vh; */
	position: fixed;
	left: 8.5vw;
	/* top: 10%; */
	margin-top: 11%;
	width: 45vw;
}

.instruction {
	position: fixed;
	right: 5%;
	margin-top: 8%;
	width: 41%;
}

html {
	font-family: "VT323", monospace;
}

.background {
	position: absolute;
	width: 100vw;
	height: auto;
	z-index: -10;
}
@media (orientation: portrait) {
	html {
		background: rgb(215, 187, 138);
		content: "Joe's Task: ";
	}
	.app {
		display: none;
	}
	.alert {
		letter-spacing: 1px;
		margin: auto;
		margin-top: 50%;
		width: 50%;
		text-align: center;
		font-size: 70px;
		font-weight: 400;
	}
}
